import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "../api.service";
import {
  MenuItem,
  round,
  MenuExtraItem,
  CartItem,
  CartExtraItem,
  TruckInfo,
  MenuExtraCategory,
  AppEventType,
} from "../models";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { getLocaleExtraDayPeriods } from "@angular/common";
import * as _ from "lodash";
import { CartService } from "../cart.service";
import { UtilsService } from "../utils.service";
import { Cart } from "../cart";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { slideInUpOnEnterAnimation } from "angular-animations";
import { OwlOptions } from "ngx-owl-carousel-o";
import { GalleryItemComponent } from "../gallery-item/gallery-item.component";
import { GoogleTagManagerService } from "../google-tag-manager.service";
@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
  animations: [slideInUpOnEnterAnimation({ delay: 1000 })],
})
export class MenuItemComponent implements OnInit {
  @ViewChild("galleryItemComponent")
  galleryItemComponent!: GalleryItemComponent;

  carts: Cart;
  existCartItem: CartItem;
  extras : MenuExtraCategory [] = [];
  isLoading: boolean;
  isOutOfStock: boolean = false;

  truck: TruckInfo;
  oldQty: number;

  topSlidersOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: true,
    dots: false,
    navSpeed: 800,
    rtl: this.trans.currentLang == "ar" ? true : false,
    navText: [" "],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };
  isShowModalImage: boolean;
  showImageIndex: number;

  constructor(
    private api: ApiService,
    private cart: CartService,
    private route: ActivatedRoute,
    private router: Router,
    public utils: UtilsService,
    private toaster: ToastrService,
    private trans: TranslateService,
    private gtagService: GoogleTagManagerService
  ) {
    this.carts = cart.getCart();
  }
  pageUrl: string;
  get selectedExtras(): Array<MenuExtraItem> {
    if (!this.item) return [];
    return _(this.item.extras)
      .map((c) =>
        c.extraItems.map((ei) => {
          ei.catId = c.extraCategory.id;
          return ei;
        })
      )
      .concat()
      .flatten()
      .filter((c) => c.qty > 0)
      .value();
  }
  get calculatedPrice(): number {
    if (!this.item) return;
    let sumExtras = _.sumBy(this.selectedExtras, (d) => d.price * d.qty);
    return round(this.item.itemInfo.price + sumExtras || 0);
  }
  get subTotal(): number {
    if (!this.item) return;
    let sumExtras = _.sumBy(this.selectedExtras, (d) => {
      if(d.posRocket_OrderOptions)
     return d.price * d.qty
    else 
    return  d.price * d.qty * this.qty

    });
    return round(this.item.itemInfo.price * this.qty + (sumExtras || 0));
  }
  item: MenuItem;
  qty: number = 1;
  notes: string = "";
  ngOnInit() {
    this.isLoading = true;
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          let id = params.get("id");
          this.pageUrl = this.utils.getUrl("item/" + id);
          this.api.trackEvent(AppEventType.ViewItem, Number(id));
          return this.api.viewItem(Number(id));
        })
      )
      .subscribe((i) => {
        this.item = _.cloneDeep(i);
this.extras = this.item.extras.filter(x=>x.extraCategory.extraItemId == null);
        this.checkOutOfStock();
        this.checkCartItemExists();
        this.utils.fbTrack("PageView", {
          currency: "KWD",
          price: this.item.itemInfo.price,
          value: this.item.itemInfo.price,
          item_id: this.item.itemInfo.id,
          content_type: "product",
          content_ids: this.item.itemInfo.id,
        });
        this.utils.ttqTrack("PageView", {
          content_id: this.item.itemInfo.id,
          content_type: "product",
          content_name: this.item.itemInfo.title.en,
          quantity: this.item.qty,
          price: this.item.itemInfo.price,
          value: this.item.itemInfo.price,
          currency: "KWD",
        });
        this.utils.snapchatTrack("PAGE_VIEW");
        this.viewItem();

        this.api.viewTruck().subscribe((truck) => {
          this.isLoading = false;
          this.truck = truck.truck;
          if (!this.utils.isNavigationAllowed(this.truck)) {
            this.router.navigate(["/home"]);
            return;
          }
          this.isOutOfStock = this.checkOutOfStock();
        });
      });
    $("#type-icon").click(function () {
      $("#notes").focus();
    });
  }
  changeCatSelectedItem(
    extraCategory: MenuExtraCategory,
    extra: MenuExtraItem
  ) {
    extraCategory["selected"] = extra.id;
    if (extra.qty < 1) {
      this.addExtraItem(extra, extraCategory);
    }
    var found = this.item.extras.find(x=>x.extraCategory.extraItemId == extra.id)
    if(found){
      this.extras = this.item.extras.filter(x=>x.extraCategory.extraItemId == null);
      this.extras.push(found);}
  }
  isAddon(item: MenuItem): boolean {
    if (!(item.extras.length > 0)) {
      return false;
    }

    let filter = item.extras.filter((item) => item.extraCategory.minQty > 0);

    return filter && filter?.length > 0;
  }
  viewItem() {
    let newItem = {
      id: this.item.itemInfo.id,
      name: this.item.itemInfo.title.en,
      quantity: this.item.qty,
      price: this.item.itemInfo.price,
      category: "Products",
      variant: "Products",
      brand: this.item.itemInfo?.truckName?.en
        ? this.item.itemInfo?.truckName?.en
        : this.item.itemInfo?.truckName?.ar
          ? this.item.itemInfo?.truckName?.ar
          : "Broker",
    };
    this.gtagService.viewItem(newItem);
  }
  extraDisabled(ecat, extra) {
    return (
      !extra.isSelected &&
      _.filter(ecat.extraItems, (ei) => ei.isSelected).length >=
      ecat.extraCategory.maxQty
    );
  }
  clearSelectedExtras(ecat) {
    _.forEach(ecat.extraItems, (ei) => {
      (ei.isSelected = false), (ei.qty = 0);
    });
  }
  hasSelectedExtras(ecat) {
    return _.some(ecat.extraItems, (ei) => ei.isSelected);
  }
  addQty() {
    if (!this.item) return;
    let oldQty = 0;
    if (this.existCartItem) oldQty = this.existCartItem.qty;
    if (
      !this.cart.validateItemToAdd(
        this.item,
        oldQty,
        this.qty + 1 - oldQty,
         (this.qty + 1 - oldQty) * this.calculatedPrice
    // this.subTotal
      )
    )
      return;
    this.qty = this.qty + 1;
    this.gtagService.addToCartEventEmitter(
      {
        id: this.item.itemInfo?.id,
        name: this.item.itemInfo?.title?.en,
        category: "Products",
        price: this.item.itemInfo?.price,
        quantity: this.qty,
        brand: this.item.itemInfo?.truckName?.en
          ? this.item.itemInfo?.truckName?.en
          : this.item.itemInfo?.truckName?.ar
            ? this.item.itemInfo?.truckName?.ar
            : "broker",
      },
      "add"
    );
  }
  addLess() {
    if (this.qty > 1) {
      this.qty = this.qty - 1;
      this.gtagService.addToCartEventEmitter(
        {
          id: this.item.itemInfo?.id,
          name: this.item.itemInfo?.title?.en,
          category: "Products",
          price: this.item.itemInfo?.price,
          quantity: this.qty,
          brand: this.item.itemInfo?.truckName?.en
            ? this.item.itemInfo?.truckName?.en
            : this.item.itemInfo?.truckName?.ar
              ? this.item.itemInfo?.truckName?.ar
              : "broker",
        },
        "remove"
      );
    }
  }
  addToCart(checkout: boolean = false) {
    if (!this.item) return;
    let oldQty = 0;
    if (this.existCartItem) oldQty = this.existCartItem.qty;
    if (
      !this.cart.validateItemToAdd(
        this.item,
        oldQty,
        this.qty - oldQty,
         (this.qty - oldQty) * this.calculatedPrice,
       // this.subTotal,
        true
      )
    )
      return;

    if (this.existCartItem) {
      this.existCartItem.qty = this.qty;
      if (this.selectedExtras.length)
        this.existCartItem.extras = _.map(this.selectedExtras, (ei) => {
          let res = new CartExtraItem();
          res.$extraItem = ei;
          return res;
        });
      this.cart.saveCart();
    } else {
      this.cart.addItem(
        _.assignIn(new CartItem(), {
          $item: this.item.itemInfo,
          extras: _.map(this.selectedExtras, (ei) => {
            let res = new CartExtraItem();
            res.$extraItem = ei;
            return res;
          }),
          qty: this.qty,
          notes: this.notes,
        })
      );
    }
    this.utils.fbTrack("AddToCart", {
      currency: "KWD",
      price: this.item.itemInfo.price,
      value: this.item.itemInfo.price,
      item_id: this.item.itemInfo.id,
      content_type: "product",
      content_ids: this.item.itemInfo.id,
    });
    this.utils.ttqTrack("AddToCart", {
      content_id: this.item.itemInfo.id,
      content_type: "product",
      content_name: this.item.itemInfo.title.en,
      quantity: this.item.qty,
      price: this.item.itemInfo.price,
      value: this.item.itemInfo.price,
      currency: "KWD",
    });

    this.utils.snapchatTrack("ADD_CART", {
      currency: "KWD",
      price: this.item.itemInfo.price,
      value: this.item.itemInfo.price,
      item_id: this.item.itemInfo.id,
      content_type: "product",
      content_ids: this.item.itemInfo.id,
    });
    // this.gtagService.addToCartEventEmitter(
    //   {
    //     id: this.item.itemInfo.id.toString(),
    //     name: this.item.itemInfo.title.en,
    //     category: this.item.catId.toString() || "",
    //     price: this.item.itemInfo.price,
    //     quantity: this.item.qty,
    //   },
    //   "add"
    // );
    this.api.trackEvent(AppEventType.AddToCart, this.item.itemInfo.id, {
      qty: this.qty,
    });
    //this.router.navigate(['/categories/' + this.item.catId + '/items']);
    if (checkout) this.router.navigate(["/cart"]);
    else this.utils.goBack();
  }
  checkOutOfStock() {
    return this.cart.isOutOfStock(this.item);
  }

  generateShareUrl() {
    return location.href;
  }
  goBack() {
    debugger
    this.utils.goBack();
  }
  checkCartItemExists() {
    // if(this.item.extras.length)
    //   return;
    this.oldQty = this.item.qty;
    let found = _.find(
      this.carts.cartItems,
      (c) => c.$item.id == this.item.itemInfo.id
    );
    if (found) {
      //&& !this.item.extras.length
      this.existCartItem = found;
      this.qty = found.qty;
    }

    if (this.item.extras.length) {
      // Get existing qtys for extras and increase or decrease
      let allExtras = _(this.item.extras)
        .map((c) => c.extraItems)
        .concat()
        .flatten()
        .value();
      _.forEach(allExtras, function (extra) {
        if (found)
          var foundExtra = _.find(found.extras, function (cartExtraItem) {
            return cartExtraItem.$extraItem.id == extra.id;
          });
        extra.qty = !found || !foundExtra ? 0 : foundExtra.$extraItem.qty;
      });
    }
  }

  checkExtraOutOfStock(extra) {
    return (
      extra.isStocked &&
      (extra.stockQty == 0 || !extra.stockQty) &&
      !extra.saleOutStocked
    );
  }

  addExtraItem(extra: MenuExtraItem, cat: MenuExtraCategory) {
    // debugger
    // let totalExtrasQty = 0
    // if (extra.posRocket_OrderOptions)
    //   totalExtrasQty = (extra.qty + 1) * extra.price;
    // else
    let totalExtrasQty = _.sumBy(
        cat.extraItems,
        (ei) => ei.qty + (ei.id == extra.id ? 1 : 0)
      );

    if (
      !this.cart.validateExtraItemToAdd(
        extra,
        extra.qty,
        1,
        totalExtrasQty,
        this.qty,
        cat.extraCategory
      )
    )
      return;

    extra.qty += 1;
  }
  showImages(content, index: number = 0) {
    let imgs = [];
    if (_.isArray(content)) {
      imgs = content;
      imgs.forEach((element) => {
        element.url = element.pic;
      });
    } else {
      imgs.push({ url: content });
    }
    this.showImageIndex = index;
    this.galleryItemComponent.openModal(imgs, index);
  }

  removeExtraItem(extra) {
    extra.qty -= 1;
    if (extra.qty < 0) extra.qty = 0;
    // if(sumOfExtraCatQty < ((ec.extraCategory.minQty || 0) * this.qty)){ // check if it less than min qty
    //   this.trans.get('MIN_Qty_EXTRA', {ec:ec.extraCategory}).subscribe(msg=>{
    //     this.toaster.error(msg);
    //   });
    //   errorFound = true;
    //   return false;
    // }
  }
}
